import { styled } from '@linaria/react';
import { Flex } from '@mantine/core';
import { useMemo } from 'react';
import { Integration } from '../../../api/integrations-client/integrations-client.type';
import { Divider } from '../../../ui-library/divider/divider';
import { Text } from '../../../ui-library/typography/typography';
import { AddNewIntegrationCard } from './add-new-integration-card';
import { getIntegrationName } from './add-new-integration.helpers';

type Props = {
  integrations: Integration[];
  onClickCard: (integration: Integration) => void;
  isUpcoming?: boolean;
};

export const AddNewIntegrationSection = ({ integrations, onClickCard, isUpcoming = false }: Props) => {
  const sortedIntegrations = useMemo(
    () => [...integrations].sort((a, b) => getIntegrationName(a).localeCompare(getIntegrationName(b))),
    [integrations],
  );

  return (
    <Flex direction="column" gap={32}>
      <Flex direction="column" gap={8}>
        <Text size="large" lineHeight="none" weight="bold" style={{ padding: '6px 0' }}>
          {isUpcoming ? 'Upcoming Integrations' : 'Available Integrations'}
        </Text>
        <Divider />
      </Flex>
      <IntegrationGrid>
        {sortedIntegrations.map((integration) => (
          <AddNewIntegrationCard
            key={integration.id}
            integration={integration}
            onClickCard={onClickCard}
            isUpcoming={isUpcoming}
          />
        ))}
      </IntegrationGrid>
    </Flex>
  );
};

const IntegrationGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 24px;
  padding: 0 2px;
`;
