type WorkflowsState = {
  workflowName: string;
  selectedBoards: string[];
  statuses: string[];
  mappedStatuses: { [key: string]: string[] };
};

type WorkflowsReducerAction = {
  type:
    | 'ADD_TO_STATUS_LIST'
    | 'REMOVE_FROM_STATUS_LIST'
    | 'ADD_STATUS_TO_BUCKET'
    | 'REMOVE_STATUS_FROM_BUCKET'
    | 'REMOVE_STATUS_FROM_ALL_BUCKETS'
    | 'CLEAR_STATE'
    | 'SET_WORKFLOW_NAME'
    | 'SET_SELECTED_BOARDS';
  payload: {
    workflowName: string | null;
    bucket: string | null;
    status: string;
    statusIndex: number | null;
    selectedBoards: string[];
  };
};

/**
 * Reducer for the workflows state
 * @param {WorkflowsState} state The current state
 * @param {WorkflowsReducerAction} action The action to perform
 * @returns {WorkflowsState} The new state
 */
function workflowsReducer(state: WorkflowsState, action: WorkflowsReducerAction): WorkflowsState {
  switch (action.type) {
    case 'ADD_TO_STATUS_LIST':
      return {
        ...state,
        // Step 1: Add the status to the status list
        statuses: state.statuses.includes(action.payload.status)
          ? state.statuses
          : action.payload.statusIndex
            ? [
                ...state.statuses.slice(0, action.payload.statusIndex),
                action.payload.status,
                ...state.statuses.slice(action.payload.statusIndex),
              ]
            : [...state.statuses, action.payload.status],
        // Step 2: Remove the status from ALL bucket mappings
        mappedStatuses: Object.entries(state.mappedStatuses).reduce((acc, [bucket, statuses]) => {
          return { ...acc, [bucket]: statuses.filter((status) => status !== action.payload.status) };
        }, {}),
      };

    case 'REMOVE_FROM_STATUS_LIST':
      return {
        ...state,
        statuses: state.statuses.filter((status) => status !== action.payload.status),
      };

    case 'ADD_STATUS_TO_BUCKET':
      if (!action.payload.bucket) {
        return state;
      }

      // Step 0: Remove the status from ALL bucket mappings
      Object.entries(state.mappedStatuses).forEach(([bucket, statuses]) => {
        state.mappedStatuses[bucket] = statuses.filter((status) => status !== action.payload.status);
      });

      return {
        ...state,
        // Step 1: Remove the status from the status list
        statuses: state.statuses.filter((status) => status !== action.payload.status),
        // Step 2: Remove the status from ALL bucket mappings EXCEPT the one we are mapping to
        mappedStatuses:
          action.payload.bucket in state.mappedStatuses
            ? Object.entries(state.mappedStatuses).reduce<{ [key: string]: string[] }>((acc, [bucket, statuses]) => {
                if (bucket === action.payload.bucket) {
                  acc[bucket] = [...statuses, action.payload.status];
                } else {
                  acc[bucket] = statuses.filter((status) => status !== action.payload.status);
                }
                return acc;
              }, {})
            : { [action.payload.bucket]: [action.payload.status], ...state.mappedStatuses },
      };

    case 'REMOVE_STATUS_FROM_BUCKET':
      if (!action.payload.bucket || !action.payload.status) {
        return state;
      }
      return {
        ...state,
        mappedStatuses: {
          ...state.mappedStatuses,
          [action.payload.bucket]: state.mappedStatuses[action.payload.bucket].filter(
            (status) => status !== action.payload.status,
          ),
        },
      };

    case 'REMOVE_STATUS_FROM_ALL_BUCKETS':
      if (!action.payload.status) {
        return state;
      }
      return {
        ...state,
        mappedStatuses: Object.entries(state.mappedStatuses).reduce((acc, [bucket, statuses]) => {
          return { ...acc, [bucket]: statuses.filter((status) => status !== action.payload.status) };
        }, {}),
      };

    case 'SET_WORKFLOW_NAME':
      if (!action.payload.workflowName) {
        return state;
      }
      return {
        ...state,
        workflowName: action.payload.workflowName,
      };

    case 'SET_SELECTED_BOARDS':
      return {
        ...state,
        selectedBoards: action.payload.selectedBoards,
      };

    case 'CLEAR_STATE':
      return {
        workflowName: '',
        statuses: [],
        mappedStatuses: {},
        selectedBoards: [],
      };

    default:
      console.error('Unknown action type', action.type);
      return state;
  }
}

export { workflowsReducer };
export type { WorkflowsReducerAction, WorkflowsState };
