import { styled } from '@linaria/react';
import { Flex, ScrollArea } from '@mantine/core';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Integration, IntegrationAuthType } from '../../../api/integrations-client/integrations-client.type';
import { useOrgId } from '../../../store/global-store/global-store.hooks';
import { useIntegrationsStore } from '../../../store/integrations-store/integrations-store';
import { setIntegration } from '../../../store/integrations-store/integrations-store.actions';
import { baseWhite } from '../../../styles/design-tokens';
import { Button } from '../../../ui-library/button/button';
import { Icon } from '../../../ui-library/icon/icon';
import { H3 } from '../../../ui-library/typography/typography';
import { MenuDrivenContainer } from '../../menu-driven-container/menu-driven-container';
import { AddNewIntegrationModal } from './add-new-integration-modal';
import { AddNewIntegrationSection } from './add-new-integration-section';

export const AddNewIntegration = () => {
  const navigate = useNavigate();

  const organizationId = useOrgId();
  const integrations = useIntegrationsStore((state) => state.integrations);
  const availableIntegrations = integrations.filter((integration) => integration.enabled);
  const upcomingIntegrations = integrations.filter((integration) => !integration.enabled);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleClickCard = (integration: Integration) => {
    setIntegration(integration);
    setIsOpenModal(true);
  };

  const handleStartOauth = (integration: Integration, baseUrl?: string) => {
    const receivedUrl = new URL(integration.auth_url);
    const params = new URLSearchParams(receivedUrl.search);
    params.set('state', `${baseUrl},${organizationId || ''}`);
    const updatedQueryString = params.toString();

    window.location.href = `${receivedUrl.origin}${receivedUrl.pathname}?${updatedQueryString}`;
  };

  const handleStartIntegration = (integration: Integration, baseUrl?: string) => {
    if (integration.auth_type !== IntegrationAuthType.Oauth) {
      navigate('/application/integrations/authorize');
    } else {
      handleStartOauth(integration, baseUrl);
    }

    setIsOpenModal(false);
  };

  const header = (
    <Flex align="center" gap={16}>
      <Button
        variant="secondary"
        radius="xl"
        leftSection={<Icon name="arrow_back" size={18} color={baseWhite} />}
        onClick={() => navigate('/application/integrations-list')}
      >
        Back
      </Button>
      <Flex align="center" gap={8}>
        <Icon name="hub" size={32} />
        <H3>Add New Integrations</H3>
      </Flex>
    </Flex>
  );

  return (
    <MenuDrivenContainer header={header}>
      <ScrollContainer scrollbarSize={8}>
        <Flex direction="column" gap={32}>
          <AddNewIntegrationSection integrations={availableIntegrations} onClickCard={handleClickCard} />
          {upcomingIntegrations.length > 0 && (
            <AddNewIntegrationSection integrations={upcomingIntegrations} onClickCard={handleClickCard} isUpcoming />
          )}
        </Flex>
      </ScrollContainer>
      <AddNewIntegrationModal
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        onStartIntegration={handleStartIntegration}
      />
    </MenuDrivenContainer>
  );
};

const ScrollContainer = styled(ScrollArea)`
  box-sizing: border-box;
  padding: 16px 32px 32px 32px;
  background: var(--base-white);
  border-radius: 8px;
  height: 100%;
  overflow-y: auto;
`;
