import { Divider, TextInput } from '@mantine/core';
import { Button } from '../../../../ui-library/button/button';
import { Icon } from '../../../../ui-library/icon/icon';

import { styled } from '@linaria/react';
import { Workflow } from '../../../../api/workflows-client/workflows-client.type';
import { blueLighter, skyDark } from '../../../../styles/design-tokens';
import { Text } from '../../../../ui-library/typography/typography';

export function WorkflowsList({
  workflows,
  onCreate,
  onSelectWorkflow,
}: {
  workflows: Workflow[];
  onCreate: () => void;
  onSelectWorkflow: (workflow: Workflow) => void;
}) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <TextInput
          placeholder="Search"
          leftSection={<Icon name="search" size={16} color={skyDark} />}
          style={{ width: 200 }}
          radius="xl"
          size="xs"
        />
        <Button
          variant="primary"
          size="xs"
          radius="xl"
          rightSection={<Icon name="add" variant="filled" size={16} color="white" />}
          style={{ padding: '8px 16px', gap: '8px' }}
          onClick={onCreate}
        >
          Create
        </Button>
      </div>
      <Divider orientation="horizontal" />
      {workflows?.length ? (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {workflows.map((workflow) => (
            <WorkflowItem
              key={workflow.id}
              onClick={() => {
                onSelectWorkflow(workflow);
              }}
            >
              {workflow.name}
            </WorkflowItem>
          ))}
        </div>
      ) : (
        <Text>No workflows found</Text>
      )}
    </div>
  );
}

const WorkflowItem = styled.div`
  padding: 8px 16px;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: start;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    background-color: ${blueLighter};
  }
`;
