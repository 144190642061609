import { TextInput, TextInputProps } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { skyBase, skyDark } from '../../styles/design-tokens';
import { Icon } from '../../ui-library/icon/icon';
import { Text } from '../../ui-library/typography/typography';

interface Props extends TextInputProps {
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onSave: () => void;
  onCancel: () => void;
}

export function EditableTextField(props: Props) {
  const [isOpen, toggle] = useDisclosure();
  return (
    <>
      {isOpen ? (
        <TextInput
          value={props.value}
          onChange={props.onChange}
          onBlur={() => {
            props.onSave();
            toggle.close();
          }}
          onKeyDown={props.onKeyDown}
          autoFocus
          size="md"
          radius="xl"
          style={{ width: '300px', height: '24px', color: skyBase }}
          disabled={props.disabled}
          rightSection={
            <Icon
              name="check_circle"
              variant="outlined"
              size={20}
              color={skyDark}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                props.onSave();
                toggle.close();
              }}
            />
          }
        />
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Text weight="bold" size="large">
            {props.value}
          </Text>
          <Icon name="edit" size={20} color={skyDark} style={{ cursor: 'pointer' }} onClick={toggle.open} />
        </div>
      )}
    </>
  );
}
