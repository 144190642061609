import { dropTargetForElements, monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { styled } from '@linaria/react';
import { Skeleton } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import invariant from 'tiny-invariant';

import { DropShadowCard } from '../../../../components/drop-shadow-card/drop-shadow-card';
import { baseWhite, primaryBase, secondaryBase, skyDark, skyLighter } from '../../../../styles/design-tokens';
import { Icon } from '../../../../ui-library/icon/icon';
import { Text } from '../../../../ui-library/typography/typography';
import { WorkflowsReducerAction, WorkflowsState } from './reducer';
import { Status } from './statuses';

const BUCKETS = ['Backlog', 'In Progress', 'In Review', 'In Test', 'Deployable', 'Done'];

export function WorkflowConfiguration({
  state,
  dispatch,
}: {
  state: WorkflowsState;
  dispatch: (action: WorkflowsReducerAction) => void;
}) {
  useEffect(() => {
    return monitorForElements({
      onDrop: ({ source, location }) => {
        if (!location.current.dropTargets[0]) {
          return;
        }

        dispatch({
          type: 'ADD_STATUS_TO_BUCKET',
          payload: {
            workflowName: null,
            bucket: location.current.dropTargets[0].data.bucket as string,
            status: source.data.status as string,
            statusIndex: null,
            selectedBoards: [],
          },
        });
      },
    });
  }, [state.mappedStatuses, dispatch]);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '500px',
        overflow: 'hidden',
      }}
    >
      <div style={{ padding: '16px' }}>
        <Text weight="bold">Workflow Configuration</Text>
      </div>
      <div
        style={{
          flex: 1,
          minHeight: 0,
          overflow: 'auto',
          paddingBottom: '16px',
        }}
      >
        <WorkflowContainer>
          {BUCKETS.map((bucket, index) => (
            <Bucket
              key={bucket}
              name={bucket}
              ordinalPosition={index === 0 ? 'first' : index === BUCKETS.length - 1 ? 'last' : 'internal'}
              state={state}
              dispatch={dispatch}
            >
              {bucket in state.mappedStatuses &&
                state.mappedStatuses[bucket].map((status) => (
                  <Status key={status} status={status} state={state} dispatch={dispatch} />
                ))}
            </Bucket>
          ))}
        </WorkflowContainer>
      </div>
    </div>
  );
}

function Bucket({
  name,
  ordinalPosition,
  children,
  state,
  dispatch,
}: {
  name: string;
  ordinalPosition: 'first' | 'internal' | 'last';
  children: React.ReactNode;
  state: WorkflowsState;
  dispatch: (action: WorkflowsReducerAction) => void;
}) {
  const { hovered, ref } = useHover();
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  useEffect(() => {
    const el = ref.current;
    invariant(el);

    return dropTargetForElements({
      element: el,
      getData: () => ({ bucket: name }),
      onDragEnter: () => setIsDraggedOver(true),
      onDragLeave: () => setIsDraggedOver(false),
      onDrop: () => setIsDraggedOver(false),
    });
  }, [state.mappedStatuses, dispatch, name, ref]);

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <BucketIcon ordinalPosition={ordinalPosition} />
      <div style={{ height: '2px', width: '16px', backgroundColor: secondaryBase }} />
      <DropShadowCard
        ref={ref}
        style={{
          cursor: hovered ? 'grab' : 'default',
          backgroundColor: baseWhite,
          border: isDraggedOver ? `2px solid ${primaryBase}` : 'none',
        }}
      >
        <BucketContainer>
          <Text weight="bold" style={{ alignSelf: 'start' }}>
            {name}
          </Text>
          <BucketChildren isDraggedOver={isDraggedOver}>{children}</BucketChildren>
        </BucketContainer>
      </DropShadowCard>
    </div>
  );
}

function BucketIcon({ ordinalPosition }: { ordinalPosition: 'first' | 'internal' | 'last' }) {
  if (ordinalPosition === 'first') {
    return <Icon name="play_circle_outline" variant="filled" size={24} color={primaryBase} />;
  }
  if (ordinalPosition === 'last') {
    return <Icon name="check_circle_outline" variant="filled" size={24} color={secondaryBase} />;
  }
  return <Icon name="adjust" variant="filled" size={24} color={secondaryBase} />;
}

function BucketChildren({ children, isDraggedOver }: { children: React.ReactNode; isDraggedOver: boolean }) {
  if (isDraggedOver) {
    if (children && Array.isArray(children) && children.length) {
      return (
        <>
          {children}
          <Skeleton height={32} width={200} />
        </>
      );
    }
    return <Skeleton height={32} width={200} />;
  }
  if (children && Array.isArray(children) && children.length) {
    return children;
  }
  return <EmptyState>Empty</EmptyState>;
}

const BucketContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-height: 100px;
  min-width: 200px;
  padding: 8px;
`;

const EmptyState = styled.div`
  padding: 4px 8px;
  border: 1px dashed ${skyDark};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  align-self: center;
`;

const WorkflowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  background-color: ${skyLighter};
  padding: 8px;
  height: fit-content;
`;
