import { styled } from '@linaria/react';
import { Integration } from '../../../api/integrations-client/integrations-client.type';
import { inkLighter } from '../../../styles/design-tokens';
import { Text } from '../../../ui-library/typography/typography';
import { formatLogoSrc, getIntegrationName } from './add-new-integration.helpers';

type Props = {
  integration: Integration;
  onClickCard: (integration: Integration) => void;
  isUpcoming?: boolean;
};

export const AddNewIntegrationCard = ({ integration, onClickCard, isUpcoming = false }: Props) => {
  const handleClick = () => {
    if (isUpcoming) {
      return;
    }

    onClickCard(integration);
  };

  return (
    <Card onClick={handleClick}>
      <CardHeader>
        <IntegrationIcon src={formatLogoSrc(integration.logo)} alt={integration.name} />
        <Text weight="bold" lineHeight="none" style={{ flexGrow: 1 }}>
          {getIntegrationName(integration)}
        </Text>
      </CardHeader>
      <Text size="small" lineHeight="tight" color={inkLighter}>
        {integration.description}
      </Text>
      {isUpcoming && <ComingSoonBadge>Coming Soon</ComingSoonBadge>}
    </Card>
  );
};

const Card = styled.div`
  position: relative;
  padding: 24px 32px 24px 24px;
  border: 1px solid var(--sky-lighter);
  border-radius: 8px;
  transition: all 0.2s;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);

  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
`;

const IntegrationIcon = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 6px;
  object-fit: contain;
`;

const ComingSoonBadge = styled.span`
  position: absolute;
  top: 16px;
  right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 85px;
  padding: 4px 8px;
  background: var(--base-white);
  border: 1px solid var(--ink-light);
  border-radius: var(--border-radius-small);
  font-size: 12px;
  color: var(--ink-light);
  flex-shrink: 0;
`;
