import { useMutation, useQuery } from '@tanstack/react-query';
import { createWorkflow, getWorkflows, updateWorkflow } from './workflows-client';

/**
 * Hook to create a workflow
 * @returns {MutationResult} The mutation result
 */
function useCreateWorkflow() {
  const mutation = useMutation({
    mutationFn: createWorkflow,
  });

  return mutation;
}

/**
 * Hook to update a workflow
 * @returns {MutationResult} The mutation result
 */
function useUpdateWorkflow() {
  const mutation = useMutation({
    mutationFn: updateWorkflow,
  });

  return mutation;
}

/**
 * Hook to get workflows
 * @param {string | null} portfolioId The portfolio ID
 * @returns {QueryResult} The query result
 */
function useGetWorkflows(portfolioId: string | null) {
  const query = useQuery({
    queryKey: ['workflows', portfolioId],
    queryFn: portfolioId
      ? () => getWorkflows(portfolioId)
      : () => Promise.reject({ error: 'Portfolio ID is required' }),
  });

  return { data: query.data, query };
}

export { useCreateWorkflow, useGetWorkflows, useUpdateWorkflow };
