import { styled } from '@linaria/react';
import { Divider, Group } from '@mantine/core';
import { useReducer, useState } from 'react';
import { usePortfolioStatusesByProjectsAndBoards } from '../../../../api/portfolio-client/portfolio-client.hooks';
import { PortfolioStatusesByProjectsAndBoards } from '../../../../api/portfolio-client/portfolio-client.type';
import { Workflow } from '../../../../api/workflows-client/workflows-client.type';
import {
  useCreateWorkflow,
  useGetWorkflows,
  useUpdateWorkflow,
} from '../../../../api/workflows-client/workflows.client.hooks';
import { useGlobalStore } from '../../../../store/global-store/global-store';
import { Button } from '../../../../ui-library/button/button';
import { Icon } from '../../../../ui-library/icon/icon';
import { Text } from '../../../../ui-library/typography/typography';
import { EditableTextField } from '../../editable-text-field';
import { DataManagementDropShadowContainer } from '../data-management-header';
import { buildWorkflowPayload } from '../data-management.helpers';
import { Boards } from './boards';
import { workflowsReducer, WorkflowsReducerAction, WorkflowsState } from './reducer';
import { Statuses } from './statuses';
import { WorkflowConfiguration } from './workflow-configuration';
import { WorkflowsList } from './workflows-list';

export function Workflows() {
  const portfolio = useGlobalStore((state) => state.portfolio);
  const [pageState, setPageState] = useState<'update' | 'create' | 'idle'>('idle');
  const [workflowId, setWorkflowId] = useState<string | null>(null);

  const [state, dispatch] = useReducer<React.Reducer<WorkflowsState, WorkflowsReducerAction>>(workflowsReducer, {
    workflowName: 'New Workflow',
    selectedBoards: [] as string[],
    statuses: [] as string[],
    mappedStatuses: {} as { [key: string]: string[] },
  });

  const { data: workflows, query: workflowsQuery } = useGetWorkflows(portfolio?.id || null);
  const { statusesByProjectsAndBoards } = usePortfolioStatusesByProjectsAndBoards(portfolio?.id || null);
  const { mutate: createWorkflow } = useCreateWorkflow();
  const { mutate: updateWorkflow } = useUpdateWorkflow();

  function handleSelectWorkflow(workflow: Workflow, statusesByProjectsAndBoards: PortfolioStatusesByProjectsAndBoards) {
    setPageState('update');
    setWorkflowId(workflow.id);
    const statusesForSelectedBoards = Object.entries(statusesByProjectsAndBoards)
      .map(([_, project]) => project.subprojects)
      .filter((subprojects) => workflow.subprojects.some((subprojectId) => subprojects[subprojectId]))
      .flatMap((subprojects) => Object.values(subprojects))
      .flatMap(({ statuses }) => statuses);
    // Set the workflow name
    dispatch({
      type: 'SET_WORKFLOW_NAME',
      payload: { workflowName: workflow.name, bucket: null, status: '', statusIndex: null, selectedBoards: [] },
    });
    // Set the selected boards
    dispatch({
      type: 'SET_SELECTED_BOARDS',
      payload: { selectedBoards: workflow.subprojects, workflowName: '', bucket: null, status: '', statusIndex: null },
    });
    // Add the statuses to the status list
    statusesForSelectedBoards.forEach((status) => {
      dispatch({
        type: 'ADD_TO_STATUS_LIST',
        payload: { status, bucket: null, statusIndex: null, workflowName: '', selectedBoards: [] },
      });
    });
    workflow.steps.forEach((step) => {
      step.mappings.forEach((mapping) => {
        // Map the status to the bucket
        dispatch({
          type: 'ADD_STATUS_TO_BUCKET',
          payload: {
            bucket: step.name,
            status: mapping.external_name,
            workflowName: '',
            statusIndex: null,
            selectedBoards: [],
          },
        });
        // Remove the status from the status list
        dispatch({
          type: 'REMOVE_FROM_STATUS_LIST',
          payload: {
            status: mapping.external_name,
            bucket: null,
            statusIndex: null,
            workflowName: '',
            selectedBoards: [],
          },
        });
      });
    });
  }

  return (
    <WorkflowsContainer>
      <DataManagementDropShadowContainer>
        <HeaderContainer isWorkflowNameEditable={['update', 'create'].includes(pageState)}>
          {['update', 'create'].includes(pageState) ? (
            <EditableTextField
              value={state.workflowName}
              onChange={(e) =>
                dispatch({
                  type: 'SET_WORKFLOW_NAME',
                  payload: {
                    workflowName: e.currentTarget.value,
                    bucket: null,
                    status: '',
                    statusIndex: null,
                    selectedBoards: [],
                  },
                })
              }
              onSave={() => {
                // If the workflow is new, we don't need to update it
                if (!workflowId) {
                  return;
                }
                const payload = buildWorkflowPayload(state.workflowName, state.selectedBoards, state.mappedStatuses);
                updateWorkflow({ workflowId: workflowId, payload });
                workflowsQuery.refetch();
                setWorkflowId(null);
                setPageState('idle');
              }}
              onCancel={() => {}}
            />
          ) : null}
          <Group>
            <Group>
              <Button
                variant="outline"
                size="xs"
                radius="xl"
                style={{ padding: '8px 16px', gap: '8px' }}
                onClick={() => {
                  dispatch({
                    type: 'CLEAR_STATE',
                    payload: { workflowName: '', bucket: null, status: '', statusIndex: null, selectedBoards: [] },
                  });
                  setWorkflowId(null);
                  setPageState('idle');
                }}
              >
                <Text>cancel</Text>
              </Button>
            </Group>
            <Group>
              <Button
                variant="primary"
                size="xs"
                radius="xl"
                rightSection={<Icon name="keyboard_arrow_down" variant="filled" size={16} color="white" />}
                style={{ padding: '8px 16px', gap: '8px' }}
                onClick={() => {
                  const payload = buildWorkflowPayload(
                    state.workflowName || 'New Workflow',
                    state.selectedBoards,
                    state.mappedStatuses,
                  );
                  if (pageState === 'create') {
                    createWorkflow(payload);
                  } else if (pageState === 'update' && workflowId) {
                    updateWorkflow({ workflowId, payload });
                  }
                  workflowsQuery.refetch();
                  setWorkflowId(null);
                  setPageState('idle');
                }}
              >
                <Text>Save Workflow</Text>
              </Button>
            </Group>
          </Group>
        </HeaderContainer>
        <Divider style={{ marginTop: 24 }} />
        {pageState === 'idle' && statusesByProjectsAndBoards ? (
          <GridContainer>
            <GridItem>
              <WorkflowsList
                onCreate={() => {
                  dispatch({
                    type: 'SET_WORKFLOW_NAME',
                    payload: {
                      workflowName: 'New Workflow',
                      bucket: null,
                      status: '',
                      statusIndex: null,
                      selectedBoards: [],
                    },
                  });
                  setPageState('create');
                  setWorkflowId(null);
                }}
                onSelectWorkflow={(workflow) => handleSelectWorkflow(workflow, statusesByProjectsAndBoards)}
                workflows={workflows || []}
              />
            </GridItem>
            <Divider orientation="vertical" />
            <GridItem>
              <Boards state={state} dispatch={dispatch} statusesByProjectsAndBoards={statusesByProjectsAndBoards} />
            </GridItem>
            <Divider orientation="vertical" />
            <GridItem>
              <WorkflowConfiguration state={state} dispatch={dispatch} />
            </GridItem>
          </GridContainer>
        ) : (
          <GridContainer>
            <GridItem>
              <Boards state={state} dispatch={dispatch} statusesByProjectsAndBoards={statusesByProjectsAndBoards} />
            </GridItem>
            <Divider orientation="vertical" />
            <GridItem>
              <Statuses state={state} dispatch={dispatch} />
            </GridItem>
            <Divider orientation="vertical" />
            <GridItem>
              <WorkflowConfiguration state={state} dispatch={dispatch} />
            </GridItem>
          </GridContainer>
        )}
      </DataManagementDropShadowContainer>
    </WorkflowsContainer>
  );
}

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2px 1fr 2px 2fr;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const GridItem = styled.div`
  height: 100%;
  overflow: hidden;
  padding: 1em;
`;

const HeaderContainer = styled.div<{ isWorkflowNameEditable: boolean }>`
  display: flex;
  justify-content: ${({ isWorkflowNameEditable }) => (isWorkflowNameEditable ? 'space-between' : 'flex-end')};
  align-items: center;
  width: 100%;
`;

const WorkflowsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  margin: 16px 0px 0px 0px;
`;
