import { Icon } from '@iconify/react/dist/iconify.js';
import { styled } from '@linaria/react';
import { Tabs } from '@mantine/core';
import { useDataManagementStore } from '../../store/data-management-store/data-management-store';
import { Tab } from '../../store/data-management-store/data-management-store.type';
import { baseWhite, inkLight, secondaryBase, skyLightest } from '../../styles/design-tokens';
import { MenuDrivenContainerNoNavbar } from '../menu-driven-container/menu-driven-container-no-navbar';
import { BoardSettings } from './components/board-settings-tab/board-settings';
import { DataManagementHeader } from './components/data-management-header';
import { DataSources } from './components/data-sources-tab/data-sources';
import { Portfolios } from './components/portfolios-tab/portfolios';
import { Workflows } from './components/workflows-tab/workflows';

export function DataManagement() {
  const activeTab = useDataManagementStore((state) => state.activeTab);
  const setActiveTab = useDataManagementStore((state) => state.setActiveTab);

  return (
    <MenuDrivenContainerNoNavbar header={<DataManagementHeader title="Data Management" icon="account_tree" />}>
      <StyledTabs variant="pills" radius="xl" value={activeTab} onChange={(value) => setActiveTab(value as Tab)}>
        <Tabs.List>
          <Tabs.Tab
            value={Tab.DataSources}
            leftSection={<Icon icon="material-symbols:data-object" />}
            color={secondaryBase}
          >
            Data Sources
          </Tabs.Tab>
          <Tabs.Tab
            value={Tab.Portfolios}
            leftSection={<Icon icon="material-symbols:business-center" />}
            color={secondaryBase}
          >
            Portfolios
          </Tabs.Tab>
          <Tabs.Tab
            value={Tab.BoardSettings}
            leftSection={<Icon icon="material-symbols:text-snippet" />}
            color={secondaryBase}
          >
            Board Settings
          </Tabs.Tab>
          <Tabs.Tab
            value={Tab.Workflows}
            leftSection={<Icon icon="material-symbols:account-tree" />}
            color={secondaryBase}
          >
            Workflows
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value={Tab.DataSources}>
          <DataSources />
        </Tabs.Panel>
        <Tabs.Panel value={Tab.Portfolios}>
          <Portfolios />
        </Tabs.Panel>
        <Tabs.Panel value={Tab.BoardSettings}>
          <BoardSettings />
        </Tabs.Panel>
        <Tabs.Panel value={Tab.Workflows}>
          <Workflows />
        </Tabs.Panel>
      </StyledTabs>
    </MenuDrivenContainerNoNavbar>
  );
}

const StyledTabs = styled(Tabs)`
  .mantine-Tabs-list {
    gap: 8px;
  }

  .mantine-Tabs-tab {
    transition: all 0.2s ease;
    font-weight: 500;

    &[data-active] {
      color: ${baseWhite};
      background: ${secondaryBase};
    }

    &:not([data-active]) {
      color: ${inkLight};
      border: 1px solid ${inkLight};

      &:hover {
        background: ${skyLightest};
        border: 1px solid ${secondaryBase};
      }
    }
  }
`;
