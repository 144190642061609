import { styled } from '@linaria/react';
import { useEffect, useRef, useState } from 'react';
import invariant from 'tiny-invariant';

import { attachClosestEdge, extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { draggable, dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { baseWhite, primaryBase, secondaryBase } from '../../../../styles/design-tokens';
import { Icon } from '../../../../ui-library/icon/icon';
import { Text } from '../../../../ui-library/typography/typography';
import { getStatusIndex } from '../data-management.helpers';
import { WorkflowsReducerAction, WorkflowsState } from './reducer';

export function Statuses({
  state,
  dispatch,
}: {
  state: WorkflowsState;
  dispatch: (action: WorkflowsReducerAction) => void;
}) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '500px',
        overflow: 'hidden',
      }}
    >
      <div style={{ padding: '16px' }}>
        <Text weight="bold">{`Statuses (${state.statuses.length})`}</Text>
      </div>
      <div
        style={{
          flex: 1,
          minHeight: 0,
          overflow: 'auto',
          paddingBottom: '16px',
        }}
      >
        <WorkflowContainer>
          {state.statuses.map((status) => (
            <Status key={status} status={status} state={state} dispatch={dispatch} />
          ))}
        </WorkflowContainer>
      </div>
    </div>
  );
}

export function Status({
  status,
  state,
  dispatch,
}: {
  status: string;
  state: WorkflowsState;
  dispatch: (action: WorkflowsReducerAction) => void;
}) {
  const ref = useRef(null);
  // Each Status can be dragged
  const [dragging, setDragging] = useState<boolean>(false);
  // Each Status can be "dragged over" as a drop target
  const [draggedOver, setDraggedOver] = useState<'idle' | 'top' | 'bottom'>('idle');

  useEffect(() => {
    const element = ref.current;
    invariant(element);

    return combine(
      draggable({
        element,
        getInitialData: () => ({ status, type: 'status' }),
        onDragStart: () => setDragging(true),
        onDrop: () => setDragging(false),
      }),
      dropTargetForElements({
        element,
        onDragEnter: ({ location }) => {
          const target = location.current.dropTargets[0];
          if (!target) {
            return;
          }

          const closestEdgeOfTarget = extractClosestEdge(target.data) as 'top' | 'bottom';
          setDraggedOver(closestEdgeOfTarget);
        },
        onDragLeave: () => setDraggedOver('idle'),
        onDrop: ({ source, location }) => {
          const target = location.current.dropTargets[0];
          if (!target) {
            return;
          }

          const sourceData = source.data;
          const targetData = target.data;

          const closestEdgeOfTarget = extractClosestEdge(targetData);

          const newStatusIndex = getStatusIndex(
            state.statuses,
            targetData.status as string,
            closestEdgeOfTarget as 'top' | 'bottom',
          );
          dispatch({
            type: 'ADD_TO_STATUS_LIST',
            payload: {
              bucket: null,
              status: sourceData.status as string,
              statusIndex: newStatusIndex,
              workflowName: null,
              selectedBoards: [],
            },
          });
          setDraggedOver('idle');
        },
        getData({ input }) {
          return attachClosestEdge(
            { status },
            {
              element,
              input,
              allowedEdges: ['top', 'bottom'],
            },
          );
        },
      }),
    );
  }, [status, state.statuses, dispatch]);

  return (
    <DropIndicator draggedOver={draggedOver}>
      {dragging ? null : (
        <FlexContainer ref={ref}>
          <Text color={baseWhite}>{status}</Text>
          <Icon name="drag_indicator" size={16} color={baseWhite} style={{ cursor: 'grab' }} />
        </FlexContainer>
      )}
    </DropIndicator>
  );
}

const DropIndicator = styled.div<{ draggedOver: 'idle' | 'top' | 'bottom' }>`
  width: 100%;
  padding: 8px 0px;
  border-top: ${({ draggedOver }) => (draggedOver === 'top' ? `2px solid ${primaryBase}` : 'none')};
  border-bottom: ${({ draggedOver }) => (draggedOver === 'bottom' ? `2px solid ${primaryBase}` : 'none')};
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
  border-radius: 8px;
  background-color: ${secondaryBase};
  z-index: 100;
  &:hover {
    cursor: grab;
  }
`;

const WorkflowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 16px;
  height: fit-content;
  border-radius: 8px;
`;
