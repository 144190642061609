import { styled } from '@linaria/react';
import { MultiSelect } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { PortfolioStatusesByProjectsAndBoards } from '../../../../api/portfolio-client/portfolio-client.type';
import { redBase, skyDark, skyLightest } from '../../../../styles/design-tokens';
import { Icon } from '../../../../ui-library/icon/icon';
import { Text } from '../../../../ui-library/typography/typography';
import { WorkflowsReducerAction, WorkflowsState } from './reducer';

export function Boards({
  state,
  dispatch,
  statusesByProjectsAndBoards,
}: {
  state: WorkflowsState;
  dispatch: (action: WorkflowsReducerAction) => void;
  statusesByProjectsAndBoards: PortfolioStatusesByProjectsAndBoards | undefined;
}) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <Text weight="bold">Boards</Text>
      {statusesByProjectsAndBoards && (
        <>
          <MultiSelect
            placeholder="Search and add Boards"
            data={Object.keys(statusesByProjectsAndBoards).map((projectId) => ({
              key: projectId,
              group: statusesByProjectsAndBoards[projectId].name,
              items: Object.entries(statusesByProjectsAndBoards[projectId].subprojects).map(
                ([subprojectId, subproject]) => ({
                  key: subprojectId,
                  value: subprojectId,
                  label: subproject.name,
                }),
              ),
            }))}
            value={state.selectedBoards}
            onChange={(values) => {
              // Step 1: Get all statuses for the selected boards
              const statusesForSelectedBoards = Object.entries(statusesByProjectsAndBoards)
                .map(([_, project]) => project.subprojects)
                .filter((subprojects) => values.some((subprojectId) => subprojects[subprojectId]))
                .flatMap((subprojects) => Object.values(subprojects))
                .flatMap(({ statuses }) => statuses);
              // Step 2: Remove any statuses from state that have been deselected
              state.statuses.forEach((status) => {
                if (!statusesForSelectedBoards.includes(status)) {
                  dispatch({
                    type: 'REMOVE_FROM_STATUS_LIST',
                    payload: { status, bucket: null, statusIndex: null, workflowName: null, selectedBoards: [] },
                  });
                }
              });
              // Step 3: Add any new statuses to state that have been selected
              statusesForSelectedBoards.forEach((status) => {
                dispatch({
                  type: 'ADD_TO_STATUS_LIST',
                  payload: { status, bucket: null, statusIndex: null, workflowName: null, selectedBoards: [] },
                });
              });
              // Step 4: Update the selected boards
              dispatch({
                type: 'SET_SELECTED_BOARDS',
                payload: { status: '', bucket: null, statusIndex: null, workflowName: null, selectedBoards: values },
              });
            }}
            leftSection={<Icon name="search" size={16} color={skyDark} />}
            style={{ width: '100%' }}
            radius="xl"
            size="xs"
          />
          {Object.entries(statusesByProjectsAndBoards).map(([projectId, projectData]) => {
            const selectedSubprojectsFromProjectData = Object.entries(projectData.subprojects).filter(
              ([subprojectId, _]) => state.selectedBoards.includes(subprojectId),
            );
            if (!selectedSubprojectsFromProjectData.length) return null;

            return (
              <span key={projectId}>
                <Text weight="bold" size="small">
                  {projectData.name}
                </Text>
                {selectedSubprojectsFromProjectData
                  .filter(([subprojectId, _]) => state.selectedBoards.includes(subprojectId))
                  .map(([subprojectId, { name }]) => {
                    return (
                      <BoardItem
                        key={subprojectId}
                        name={name}
                        onRemove={() => {
                          // Step 1: Get all statuses for the deselected board
                          const statusesToRemove = Object.entries(statusesByProjectsAndBoards)
                            .map(([_, projectData]) => projectData.subprojects)
                            .filter((subprojects) => Object.keys(subprojects).includes(subprojectId))
                            .flatMap((subprojects) => Object.values(subprojects))
                            .flatMap(({ statuses }) => statuses);
                          // Step 2: Remove them from state
                          statusesToRemove.forEach((status) => {
                            dispatch({
                              type: 'REMOVE_FROM_STATUS_LIST',
                              payload: {
                                status,
                                bucket: null,
                                statusIndex: null,
                                workflowName: null,
                                selectedBoards: [],
                              },
                            });
                            dispatch({
                              type: 'REMOVE_STATUS_FROM_ALL_BUCKETS',
                              payload: {
                                status,
                                bucket: null,
                                statusIndex: null,
                                workflowName: null,
                                selectedBoards: [],
                              },
                            });
                          });
                          // Step 3: Update the selected boards
                          dispatch({
                            type: 'SET_SELECTED_BOARDS',
                            payload: {
                              status: '',
                              bucket: null,
                              statusIndex: null,
                              workflowName: null,
                              selectedBoards: state.selectedBoards.filter((board) => board !== subprojectId),
                            },
                          });
                        }}
                      />
                    );
                  })}
              </span>
            );
          })}
        </>
      )}
    </div>
  );
}

function BoardItem({ name, onRemove }: { name: string; onRemove: () => void }) {
  const { hovered, ref } = useHover();
  return (
    <ListItem ref={ref}>
      <Text size="small">{name}</Text>
      <Icon
        name="do_not_disturb_on"
        size={16}
        color={hovered ? redBase : skyDark}
        style={{ cursor: 'pointer' }}
        onClick={onRemove}
      />
    </ListItem>
  );
}

const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 4px;
  border-radius: 4px;
  &:hover {
    background: ${skyLightest};
  }
`;
